import React, { useState, useEffect } from "react";
import logo from "./TyrolitLogo.png";
import "./App.css";
import { API } from "aws-amplify";
import { useLocation, useParams } from 'react-router-dom'; 
import { listUsers, getCustomer, listMaterials, listBookedItems } from "./graphql/queries";
import * as mutations from './graphql/mutations';

//import { createBookedItems } from './graphql/mutations';
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Card,
} from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";

const MaterialPage = ({ signOut }) => {
  const [Customers, setCustomers] = useState([]);
  const [userID, setUserID] = useState(null);
  const [materialsByNumberAndUserID, setMaterialsByNumberAndUserID] = useState(null);
  const { user } = useAuthenticator();

  // Use useLocation to get query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  //const searchTerm = searchParams.get('id');
  const { loaction, comp, searchTerm } = useParams();

  // Alternatively, you can also use useParams if it's part of the route path
  // const { searchTerm } = useParams();

  useEffect(() => {
    searchUsers();
  }, []); // Call searchUsers when the component mounts
  
  useEffect(() => {
    // Call searchMaterial after userID and searchTerm have been set
    if (userID !== null && searchTerm !== null) {
      searchMaterial(searchTerm);
    }
  }, [userID, searchTerm]); // Call searchMaterial whenever userID or searchTerm changes


  async function searchUsers() {
    try {
      const allUsers = await API.graphql({
        query: listUsers,
      });
      const usersArray = allUsers.data.listUsers.items;
  
      // Find the user with the specified email
      const userWithEmail = usersArray.find((locuser) => locuser.eMail === user.attributes.email);
  
      if (userWithEmail) {
        const userID = userWithEmail.usersCustomerId;
        setUserID(userID); // Update the userID state
        const Customers = await API.graphql({
          query: getCustomer,
          variables: { id: userID },
        });
        setCustomers(Customers.data.getCustomer);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
    }
  }
  
  async function getMostRecentBookedItems() {
    try {
      let nextToken = null;
      let allBookedItems = [];
  
      do {
        const result = await API.graphql({
          query: listBookedItems,
          variables: { nextToken },
        });
  
        const bookedItemsArray = result.data.listBookedItems.items;
        allBookedItems = allBookedItems.concat(bookedItemsArray);
        nextToken = result.data.listBookedItems.nextToken;
  
      } while (nextToken);
  
      // Filter booked items that match the criteria (GoodsIN === true or false)
      const filteredBookedItems = allBookedItems.filter((item) => {
        return (
          item.ToolNumberID === materialsByNumberAndUserID.id &&
          item.CustomerNameID === Customers.id
        );
      });


      
      let mostRecentGoodsInItem = null;
      let mostRecentGoodsOutItem = null;
      
      if (filteredBookedItems.length > 0) {
        // Separate items based on GoodsIN value
        const goodsInItems = filteredBookedItems.filter((item) => item.GoodsIN === true);
        const goodsOutItems = filteredBookedItems.filter((item) => item.GoodsIN === false);
      
        // Sort items by Time in descending order to get the most recent entry for both cases
        mostRecentGoodsInItem = goodsInItems.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
        mostRecentGoodsOutItem = goodsOutItems.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
      }
      
      if (mostRecentGoodsInItem) {
        console.log("Most Recent Goods In Item:", mostRecentGoodsInItem);
      } else {
        console.log("No Goods In items found for the specified criteria.");
      }
      
      if (mostRecentGoodsOutItem) {
        console.log("Most Recent Goods Out Item:", mostRecentGoodsOutItem);
      } else {
        console.log("No Goods Out items found for the specified criteria.");
      }
      
      return { mostRecentGoodsInItem, mostRecentGoodsOutItem };
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
    }
  }
  
  async function getUsersByCustomerId() {
    try {
      let nextToken = null;
      let allUsers = [];
  
      do {
        const result = await API.graphql({
          query: listUsers,
          variables: { nextToken },
        });
  
        const usersArray = result.data.listUsers.items;
        allUsers = allUsers.concat(usersArray);
        nextToken = result.data.listUsers.nextToken;
  
      } while (nextToken);
  
      // Find users that match the criteria
      const usersByCustomerId = allUsers
        .filter((user) => user.usersCustomerId === Customers.id)
        .map((user) => user.eMail);
  
      if (usersByCustomerId.length > 0) {
        console.log("Users with matching criteria:", usersByCustomerId);
        // Handle the user data if needed
      } else {
        console.log("No users found for the specified criteria.");
      }
  
      return usersByCustomerId;
  
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
      return []; // Return an empty array in case of an error
    }
  }
  


  async function searchMaterial(searchTerm) {
    try {
      let nextToken = null;
      let allMaterials = [];
  
      do {
        const result = await API.graphql({
          query: listMaterials,
          variables: { nextToken },
        });
  
        const materialsArray = result.data.listMaterials.items;
        allMaterials = allMaterials.concat(materialsArray);
        nextToken = result.data.listMaterials.nextToken;
  
      } while (nextToken);
  
      // Find the material that matches the criteria
      const materialsByNumberAndUserID = allMaterials.find((material) => {
        return material.ToolNumber === searchTerm && material.materialCustomerId === userID;
      });
  
      if (materialsByNumberAndUserID) {
        console.log("Material ID - " + materialsByNumberAndUserID.id);
        console.log("Customer ID - " + Customers.id );
        setMaterialsByNumberAndUserID(materialsByNumberAndUserID);
      } else {
        console.log("Material not found for the specified criteria.");
      }
  
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
    }
  }
  
  //updateAMounts(materialsByNumberAndUserID.id,100);

  async function updateAMounts(ident, amont) {
    try {
      const materialDetails = {
        id: ident,
        Shelf_Level_Actual: amont
      };
  
      const updatedMaterial = await API.graphql({ 
        query: mutations.updateMaterial, 
        variables: { input: materialDetails }
      });
  
      // Handle the updated material data if needed
  
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
    }
  }
  

  async function BookOut() {
    const clockNumber = prompt("Please enter your clock number:");
  
    if (clockNumber !== null) {
      alert(`You entered the clock number: ${clockNumber}`);
  
      const inputBox = document.getElementById('BOButton');
      const value = parseInt(inputBox.value, 10) || 1;
      var GoodsInOut = await getMostRecentBookedItems();
      
      var mailing = await getUsersByCustomerId()
      console.log(mailing);


      try {
        if (materialsByNumberAndUserID && Customers) {
          var goodsDirection
          const bookedamount = materialsByNumberAndUserID.Shelf_Level_Actual - value;
          await updateAMounts(materialsByNumberAndUserID.id, bookedamount);
          if (value>0){
            goodsDirection = false;
          }else{
            goodsDirection = true;
          }
          console.log("Material ID - " + materialsByNumberAndUserID.id);
          console.log("Customer ID - " + Customers.id );

          await API.graphql({
            query: mutations.createBookedItems,
            variables: {
              input: {

                "Amount": value|| null,
                "LastBookedOut": GoodsInOut.mostRecentGoodsOutItem?.updatedAt|| null,
                "LastGoodsIn": GoodsInOut.mostRecentGoodsInItem?.updatedAt|| null,
                "LastBookedOut_Amount": GoodsInOut.mostRecentGoodsOutItem?.Amount|| null,
                "LastGoodsIn_Amount": GoodsInOut.mostRecentGoodsInItem?.Amount|| null,
                "GoodsIN": goodsDirection,
                "EmailList": mailing|| null,
                "ToolNumber": materialsByNumberAndUserID?.ToolNumber|| null,
                "CustomerName": Customers?.Company|| null,
                "StorageLocation": materialsByNumberAndUserID?.StorageLocation|| null,
                "Rep": Customers?.Rep|| null,
                "Notes": `clock number: ${clockNumber}`|| null,
                "ToolNumberID": materialsByNumberAndUserID?.id|| null,
                "CustomerNameID": Customers?.id || null
              }
            }
          });
        } else {
          console.log("Material or Customer is null");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else {
      alert("No clock number provided.");
    }
   window.location.reload();
  }
  
  

  return (
    <div className="MaterialPage">
      <div className="full-screen-container">
        <Image src={logo} className="App-logo" alt="logo" />
      </div>
      <Card>

        {user && (
          <div className="infotext">
            <Heading level={1} style={{ textAlign: 'center'}}>{searchTerm}</Heading>
            <p>{}</p>
            {materialsByNumberAndUserID && (
              <p>Location: {materialsByNumberAndUserID.StorageLocation}</p>
            )}
                        {materialsByNumberAndUserID && (
              <p>Specification: {materialsByNumberAndUserID.Specification}</p>
            )}
            {materialsByNumberAndUserID && (
              <p>Shape: {materialsByNumberAndUserID.Shape}</p>
            )}
            {materialsByNumberAndUserID && (
              <p>Dimensions: {materialsByNumberAndUserID.Dim_Value}</p>
            )}
            {materialsByNumberAndUserID && (
              <p>Dimensions: {materialsByNumberAndUserID.Dim_Nomclature}</p>
            )}

            
            
            {materialsByNumberAndUserID && (
            <div className="NumberItemContainer">
              <div className="NumberItem">
                <p className="cardTitle">Shelf Level</p>
                <p className="bigNumber">{materialsByNumberAndUserID.Shelf_Level_Actual}</p>
              </div>
              <div className="NumberItem">
                <p className="cardTitle">Restock Level</p>
                <p className="bigNumber">{materialsByNumberAndUserID.Restock_Level}</p>
              </div>
            </div>
          )}

<div>
  <p>
    <input id="BOButton" className="inputspaceA" type="number" placeholder="1" />
    <Button className="buttonspaceA" onClick={BookOut}>Book Out</Button>
  </p>
  <p>Use negative value to return item to shelf</p>
  <p>
    <span className="emailtext">{user.attributes.email}</span>
    <Button className="buttonspaceA" onClick={signOut}>Sign Out</Button>
  </p>
</div>
          </div>
        )}
      </Card>
    </div>
  );
};


export default withAuthenticator(MaterialPage);






