/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBookedItems = /* GraphQL */ `
  query GetBookedItems($id: ID!) {
    getBookedItems(id: $id) {
      id
      Amount
      LastBookedOut
      LastGoodsIn
      LastBookedOut_Amount
      LastGoodsIn_Amount
      GoodsIN
      EmailList
      ToolNumber
      CustomerName
      StorageLocation
      Rep
      Notes
      ToolNumberID
      CustomerNameID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBookedItems = /* GraphQL */ `
  query ListBookedItems(
    $filter: ModelBookedItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookedItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Amount
        LastBookedOut
        LastGoodsIn
        LastBookedOut_Amount
        LastGoodsIn_Amount
        GoodsIN
        EmailList
        ToolNumber
        CustomerName
        StorageLocation
        Rep
        Notes
        ToolNumberID
        CustomerNameID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      eMail
      KeyUser
      Customer {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      usersCustomerId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eMail
        KeyUser
        createdAt
        updatedAt
        usersCustomerId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      ToolNumber
      Specification
      Shape
      Dim_Nomclature
      Dim_Value
      Shelf_Level_Target
      Restock_Level
      Shelf_Level_Actual
      StorageLocation
      Customer {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      materialCustomerId
      __typename
    }
  }
`;
export const listMaterials = /* GraphQL */ `
  query ListMaterials(
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ToolNumber
        Specification
        Shape
        Dim_Nomclature
        Dim_Value
        Shelf_Level_Target
        Restock_Level
        Shelf_Level_Actual
        StorageLocation
        createdAt
        updatedAt
        materialCustomerId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      Company
      Rep
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Company
        Rep
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
